@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

body,
button,
input,
textarea,
select {
	font-family: "Inter", sans-serif;
}
body {
	margin: 0;
	padding: 0;
}
