body {
	background: #F3F3F3;
}

#logo {
	height: 38px;
}

#footer-logo {
	height: 26px;
}

footer a {
	text-decoration: none;
}